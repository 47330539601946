import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import {MenuItems} from "./MenuItems";

function Navbar() {
    return (
        <nav className="mainNavigation">
            <ul>
                {MenuItems.map((item,index)=>{
                    return(
                        <li><Link to={item.url}>{item.title}</Link></li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default Navbar
