import React from 'react';
import '../../App.css';

function Contact(){
    return(
        <div className="innerPage">
           <h2>Contact information</h2>
				
                <h3>Rayatul Islam</h3>
				<p>
					<strong>Address: </strong> Rolf E. Stenersens allé 15, H0202, 0858 Oslo, NORWAY<br />
					<strong>Mobile: </strong> <a href="tel:+4796694333">+4796694333</a><br />
				  <strong>E-mail: </strong> <a href="mailto:rayatislam@gmail.com">rayatislam@gmail.com</a><br />
				  <strong>Linkedin : </strong> <a href="https://www.linkedin.com/in/rayatul/" target="_blank">https://www.linkedin.com/in/rayatul/</a>
				</p>
			
				
            
        </div>
    );
}
export default Contact;