export const MenuItems =[
    {
        title:'Home',
        url:'/'
    },
    {
        title:'Resume',
        url:'/Resume'
    },
    {
        title:'Portfolio',
        url:'http://bright-webs.com/portfolio.html'
    },
    {
        title:'Contact',
        url:'/Contact'
    }
]