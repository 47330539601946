import React from 'react';
import '../../App.css';
import './HeroSection.css';
import IMAGES from '../../images/index.js';


function HeroSection() {
    return (
        <div>
        <section className="photoName">

			<div  className="photo">

				<img src={IMAGES.rayatul} alt="image"/>

			</div>

			<div className="myInfo">

				<strong>Hello, I am <span>Rayatul Islam</span></strong>
				<h2>Front-end Developer and UI Designer</h2>
				<hr/>
				<p>I like to make creative designs.  Feel free to take a look at my portfolio.</p>

				<div class="social">
					<a href="https://www.upwork.com/fl/rayatulislam" target="_blank"><img src={IMAGES.upwork} alt=""/></a>
					<a href="https://www.facebook.com/risratul" target="_blank"><img src={IMAGES.facebook} alt=""/></a>
					<a href="https://www.linkedin.com/in/rayatul-islam-66566697/" target="_blank"><img src={IMAGES.linkedin} alt=""/></a>
					<a href="skype:ratul248?add" target="_blank"><img src={IMAGES.skype} alt=""/></a>
				</div>

			</div>

		</section>
        </div>
    )
}

export default HeroSection;
