import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="header">
      <div class="headerWrapper">
        <h1>Rayatul Islam</h1>
        <Link to="/" className="homeLink"></Link>
        <Navbar />
      </div>
    </header>
  );
}

export default Header;
