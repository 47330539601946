import React from "react";
import { Link } from "react-router-dom";
import IMAGES from "../../images/index.js";

function ProjectItem() {
  return (
    <div className="boxes wrapper">
      <article>
        <h3>Dashboard Design and Development</h3>
        <a
          href="http://bright-webs.com/client/smart-news/hot-off-the-press.html"
          target="_blank"
        >
          <img src={IMAGES.smartnews} alt="image" />
          <span>Smart News</span>
        </a>
      </article>

      <article>
        <h3>WordPress Website Design & Development</h3>
        <a href="https://nyc-injury-attorneys.com/" target="_blank">
          <img src={IMAGES.nyc} alt="image" />
          <span>NYC Injury Attorneys</span>
        </a>
      </article>

      <article>
        <h3>Ecommerce WordPress Website Development</h3>
        <a
          href="http://bright-webs.com/client/swapno-haat.com/"
          target="_blank"
        >
          <img src={IMAGES.swapno} alt="image" />
          <span>swapno-haat.com</span>
        </a>
      </article>

      <article>
        <h3>Web Design & Development</h3>
        <a href="http://bright-webs.com/client/careconnx/" target="_blank">
          <img src={IMAGES.careconnx} alt="image" />
          <span>Care Connx</span>
        </a>
      </article>

      <article>
        <h3>Web Design & Development</h3>
        <a href="http://bright-webs.com/client/30-day/" target="_blank">
          <img src={IMAGES.thirtyday} alt="image" />
          <span>30 Day</span>
        </a>
      </article>

      <article>
        <h3>Web Design & Development</h3>
        <a href="http://www.dentalcenterwest.com/" target="_blank">
          <img src={IMAGES.dcw} alt="image" />
          <span>www.dentalcenterwest.com</span>
        </a>
      </article>

      <article>
        <h3>Web Design & Development</h3>
        <a href="http://bright-webs.com/client/secret-social/" target="_blank">
          <img src={IMAGES.secret} alt="image" />
          <span>Secret Social</span>
        </a>
      </article>

      <article>
        <h3>Web Design & Development</h3>
        <a href="http://bright-webs.com/client/nearest-you/" target="_blank">
          <img src={IMAGES.nearestyou} alt="image" />
          <span>Nearest You</span>
        </a>
      </article>

      <article>
        <h3>Web Design & Development</h3>
        <a href="http://redjaycabinets.net" target="_blank">
          <img src={IMAGES.redjaycabinets} alt="image" />
          <span>www.redjaycabinets.net</span>
        </a>
      </article>
    </div>
  );
}

export default ProjectItem;
