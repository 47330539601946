import React from 'react'
import './Footer.css';

function Footer() {
    return (
		<footer className="footer">
			<p>&copy; {new Date().getFullYear()}. All rights reserved.</p>
			
			<p>
			  <a href="https://validator.w3.org/check?uri=referer"><img
				  src="http://www.w3.org/Icons/valid-xhtml10"
				  alt="Valid XHTML 1.0!" height="31" width="88" /></a>
			</p>
			
		</footer>
    )
}

export default Footer
