import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Resume from './components/pages/Resume';
import Contact from './components/pages/Contact';
import Footer from './components/Footer/Footer';
function App() {
  return (
      <Router>
        <section className="mainWrap">
          <Header/>
          <Switch>
            <Route path='/' exact component={Home}  />
            <Route path='/Resume' exact component={Resume}  />
            <Route exact path="/http://bright-webs.com/portfolio.html" render={() => (window.location = "http://bright-webs.com/portfolio.html")} />
            <Route path='/Contact' exact component={Contact}  />
          </Switch>
          <Footer/>
        </section>
      </Router>
  );
}
export default App;
