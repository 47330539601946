import React from 'react';
import './Projects.css';
import ProjectItem from './ProjectItem';

function Projects() {
    return (
        <section className="container">
           <h2>Latest Projects</h2>
            <ProjectItem />
        </section>
    )
}

export default Projects
