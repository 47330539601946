import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

function Resume(){
    return(
        <div className="innerPage">
           <h2>Rayatul Islam</h2>
				
                <p>
					<strong>Address :</strong> Rolf E. Stenersens allé 15, H0202, 0858 Oslo, NORWAY<br />
                  <strong>Mobile</strong><strong> : </strong>+4796694333<br />
				  <strong>E-mail : </strong> <Link to="mailto:rayatislam@gmail.com">rayatislam@gmail.com</Link><br />
				  <strong>Linkedin : </strong> <Link to="https://www.linkedin.com/in/rayatul/" target="_blank">https://www.linkedin.com/in/rayatul/</Link>
				</p>
			
			
				<h3>Work Experiences</h3> 
			
				<ol>
					<li>
						<strong>Remote Front-end Developer (May 2021 to present)</strong><br />
						<p>Website Box<br />							
							Web: <Link to="https://www.websitebox.com/" target="_blank">https://www.websitebox.com/</Link>
						</p>
					</li>
					<li>
						<strong>Freelancer (August 2013 to present)</strong><br />
						<p>Upwork.com<br />
							Freelancer on <Link to="https://www.upwork.com/fl/rayatulislam" target="_blank">www.upwork.com</Link><br />
							Web: <Link to="https://www.upwork.com/fl/rayatulislam" target="_blank">https://www.upwork.com/fl/rayatulislam</Link>
						</p>
					</li>
					<li>
						<strong>FOUNDER (October 2013 to present)</strong><br />
						<p>Bright Web Solutions<br />
							Web: <Link to="http://www.bright-webs.com" target="_blank">www.bright-webs.com</Link>
						</p>
					</li>
					<li>
						<strong>Guest Trainer(Web Design) (January 2016 to December 2017)</strong><br />
						<p>Sheikh Fazilatunnasa Mujib Women Technical Training Center<br />
							Web: <Link to="http://www.skilledbangladesh.info/dhaka_mohila/" target="_blank">www.skilledbangladesh.info/dhaka_mohila/</Link>
						</p>
					</li>
					<li>
						<strong>SOFTWARE ENGINEER (January 2012 - November 2013)</strong><br />
						<p>Looogo <br />
						Web: <Link to="http://www.looogo-web.com" target="_blank">www.looogo-web.com</Link></p>
					</li>
					
				</ol>
				
				<h3>Education</h3>
			
			
				<ol>
					<li>
						<p><strong>M.S. in Informatics: Programming and System Architecture</strong></p>
						<p>
							(In Progress)<br />
							Institution : University of Oslo<br />
						   Place: Oslo, Norway
						</p>
					</li>
					<li>
						<p><strong>BSC IN COMPUTER SCIENCE AND ENGINEERING</strong></p>
						<p>
							Year: 2015<br />
							Institution : BUBT<br />
						   Place: Dhaka, Bangladesh
						</p>
					</li>
					<li>
						<p><strong>DIPLOMA IN COMPUTER ENGINEERING</strong></p>
						<p>
							Year: 2011<br />
							Institution : Dhaka International University<br />
						   Place: Dhaka, Bangladesh
						</p>
					</li>
				</ol>
				
            
        </div>
    );
}
export default Resume;