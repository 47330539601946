import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection/HeroSection';
import Projects from '../Projects/Projects';

function Home(){
    return(
        <>
            <HeroSection />
            <Projects />
        </>
    );
}
export default Home;