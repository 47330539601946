const IMAGES = {
  rayatul: require("./rayatul-islam.jpg").default,
  facebook: require("./facebook.png").default,
  linkedin: require("./linkedin.png").default,
  skype: require("./skype.png").default,
  upwork: require("./upwork.png").default,
  smartnews: require("./smartnews.jpg").default,
  walcraft: require("./walcraft.jpg").default,
  nyc: require("./nyc.jpg").default,
  swapno: require("./swapno.jpg").default,
  careconnx: require("./care-connx.jpg").default,
  thirtyday: require("./thirty-day.jpg").default,
  dcw: require("./p1.jpg").default,
  secret: require("./secret.jpg").default,
  nearestyou: require("./nearest-you.jpg").default,
  captivo: require("./p2.jpg").default,
  redjaycabinets: require("./p3.jpg").default,
};
export default IMAGES;
